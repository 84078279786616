import React, { memo, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next';

import Dialog from '@/components/Dialog';

function index() {
    const { t } = useTranslation()
    const [viewDialog, setViewDialog] = useState(false)
    return <>
        <div className='cmp-rule' onClick={() => setViewDialog(true)}>
            <div className='cmp-rule-btn'><span>{t('Common.Rule')}</span></div>
            <Dialog viewDialog={viewDialog} setViewDialog={setViewDialog} title="Common.Rule" viewClose>
                <div className='text'>
                    {new Array(6).fill(0).map((_, i) =>
                        <span key={i}>{t(`RamadanAnswer.Rule.Rule${i + 1}`)}</span>
                    )}
                </div>
            </Dialog>
        </div>
    </>
}

export default memo(index)